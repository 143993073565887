.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: 2em;
}

.App-header {
  text-align: center;
  background-color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.btn-login {
  background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
  padding: 10px 20px 10px 20px;
  border: 0px;
}

.color-nav {
    background-color: rgba(228, 0, 43, 1);
    border-bottom: 1px solid #171717;
}
